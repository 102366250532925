const ProductList = {
  "1": {
    type: "3D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/quickdemo/machinery/T590.png",
    tkid: "23111cfc-51cc-48e5-83e7-8a5609e5a946",
    scene: "509c953d-9262-400f-8454-77752fa8d4a3",
    config: {
      "Front Equipment": {
        assetId: "dd084a37-9dc4-463a-aeff-be2f123c5af9",
      },
    },
    name: "Skid-Steer",
    brand: "Configurable Heavy Machinery",
  }
};
export { ProductList };
