import React from "react";
// import './Product.css';
import "antd/dist/antd.css";
import { PageHeader } from "antd";
import { ProductList } from "../config/Configs.js";

class Product extends React.Component {
  render() {
    const productId = this.props.match.params.productId;
    const product = ProductList[productId];
    return (
      <div>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => window.location.assign("/")}
          title={product.name}
        />
        <div>
        </div>
        {product.type == "3D" ? (
          <div className="tk-container">
            <div
              id="player"
              style={{
                height: '50vh',
                width: "96%",
                marginLeft: "auto",
                marginRight: "auto",
                background: "#F0F2F5",
              }}
            ></div>
          </div>
        ) : (
          <p>This is a 2D product</p>
        )}
      </div>
    );
  }

  componentDidMount() {
    const productId = this.props.match.params.productId;
    const product = ProductList[productId];
    console.log("Component mounted");

    // Put player here
    window
      .threekitPlayer({
        authToken: "a21458d8-5259-4da3-923d-2c4826b6a241",
        el: document.getElementById("player"),
        stageId: product.scene,
        assetId: product.tkid,
        initialConfiguration: product.config,
        showConfigurator: true,
        showAR: true,
      })
      .then(async (api) => {
        window.player = api;
        window.configurator = await api.getConfigurator();
      });
  }
}

export default Product;
